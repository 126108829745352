.rc-tooltip.rc-tooltip-zoom-enter,
.rc-tooltip.rc-tooltip-zoom-leave {
  display: block;
}
.rc-tooltip-zoom-enter,
.rc-tooltip-zoom-appear {
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rc-tooltip-zoom-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
.rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
  -webkit-animation-name: rcToolTipZoomIn;
  animation-name: rcToolTipZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
.rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
  -webkit-animation-name: rcToolTipZoomOut;
  animation-name: rcToolTipZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}
@-webkit-keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}
@-webkit-keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
  }
}
.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: transparent;
  padding: 1px;
  padding-bottom: -3px;
  opacity: 0.9;
  transition: all 350ms ease-in-out !important;
}
.rc-tooltip-hidden {
  visibility: hidden;
  opacity: 0;
}

.rc-tooltip-inner {
  padding: 8px 10px !important;
  color: #21baea !important;
  text-align: left !important;
  text-decoration: none !important;
  background-color: white !important;
  border-radius: 3px !important;
  min-height: 34px !important;
  border: 1px solid #21baea !important;
  margin-bottom: 3px !important;
  min-width: 15px !important;
  max-width: 350px !important;
}
.rc-tooltip-profileicon {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: transparent;
  padding: 1px;
  padding-bottom: -3px;
  opacity: 1;
  transition: all 350ms ease-in-out !important;
}
.rc-tooltip-profileicon-hidden {
  visibility: hidden;
  opacity: 0;
}

.rc-tooltip-profileicon-inner {
  padding: 8px 10px !important;
  color: #083e66 !important;
  text-align: left !important;
  text-decoration: none !important;
  background-color: white !important;
  border-radius: 3px !important;
  min-height: 34px !important;
  margin-bottom: 3px !important;
  width: 150px !important;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 1024px) {
  .rc-tooltip-inner {
    width: 200px;
  }
}
.rc-tooltip-arrow,
.rc-tooltip-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 3px;
}
.rc-tooltip-profileicon-arrow,
.rc-tooltip-profileicon-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 3px;
}
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  bottom: -5px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #083e66;
}
.rc-tooltip-placement-top .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-topRight .rc-tooltip-arrow-inner {
  bottom: 1px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: #ffffff;
}
.rc-tooltip-placement-top .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 15%;
}
.rc-tooltip-placement-right .rc-tooltip-arrow,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  left: -5px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #083e66;
}
.rc-tooltip-placement-right .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow-inner {
  left: 1px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: #ffffff;
}
.rc-tooltip-placement-right .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-rightTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  right: -5px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #083e66;
}
.rc-tooltip-placement-left .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow-inner,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow-inner {
  right: 1px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: #ffffff;
}
.rc-tooltip-placement-left .rc-tooltip-arrow {
  top: 50%;
}
.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
  top: 15%;
  margin-top: 0;
}
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  bottom: 15%;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  top: -5px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff !important;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow-inner {
  top: 1px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff;
}
.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  left: 50%;
}
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
  left: 15%;
}
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
  right: 10% !important;
}

.rc-tooltip-profileicon-placement-bottom .rc-tooltip-profileicon-arrow,
.rc-tooltip-profileicon-placement-bottomLeft .rc-tooltip-profileicon-arrow,
.rc-tooltip-profileicon-placement-bottomRight .rc-tooltip-profileicon-arrow {
  top: -5px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #083e66 !important;
}
.rc-tooltip-profileicon-placement-bottom .rc-tooltip-profileicon-arrow-inner,
.rc-tooltip-profileicon-placement-bottomLeft
  .rc-tooltip-profileicon-arrow-inner,
.rc-tooltip-profileicon-placement-bottomRight
  .rc-tooltip-profileicon-arrow-inner {
  top: 1px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: #ffffff;
}
.rc-tooltip-profileicon-placement-bottom .rc-tooltip-profileicon-arrow {
  left: 50%;
}
.rc-tooltip-profileicon-placement-bottomLeft .rc-tooltip-profileicon-arrow {
  left: 15%;
}
.rc-tooltip-profileicon-placement-bottomRight .rc-tooltip-profileicon-arrow {
  right: 5% !important;
}

/* EXPIRED NOTICE TOOLTIP */
.rc-tooltip-expired-notice {
  margin-top: 5px;
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: transparent;
  padding: 1px;
  padding-bottom: -3px;
  opacity: 0.8;
  transition: all 350ms ease-in-out !important;
}
.rc-tooltip-expired-notice-hidden {
  visibility: hidden;
  opacity: 0;
}

.rc-tooltip-expired-notice-inner {
  padding: 8px 20px !important;
  color: #ffffff !important;
  text-align: left !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  min-height: 34px !important;
  border: 1px solid #083e66 !important;
  margin-bottom: 3px !important;
  max-width: 350px !important;
  background-color: #131313;
}

.rc-tooltip-expired-notice-arrow,
.rc-tooltip-expired-notice-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 3px;
}

.rc-tooltip-expired-notice-placement-bottom .rc-tooltip-expired-notice-arrow {
  top: -5px;
  margin-left: -28px;
  border-width: 12px 12px 0;
  border-top-color: #131313;
  transform: scaleY(-1);
}

.rc-tooltip-expired-notice-placement-bottom .rc-tooltip-expired-notice-arrow {
  left: 50%;
}

/* ADA TOOLTIP */
.rc-tooltip-ada {
  margin-top: 5px;
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: transparent;
  padding: 1px;
  padding-bottom: -3px;
  opacity: 0.8;
  transition: all 350ms ease-in-out !important;
}
.rc-tooltip-ada-hidden {
  visibility: hidden;
  opacity: 0;
}

.rc-tooltip-ada-inner {
  padding: 8px 20px !important;
  color: #ffffff !important;
  text-align: left !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  min-height: 34px !important;
  border: 1px solid #083e66 !important;
  margin-bottom: 3px !important;
  max-width: 350px !important;
  background-color: #131313;
}

.rc-tooltip-ada-arrow,
.rc-tooltip-ada-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 3px;
}

.rc-tooltip-ada-placement-bottom .rc-tooltip-ada-arrow {
  top: -5px;
  margin-left: -12px;
  border-width: 12px 12px 0;
  border-top-color: #131313;
  transform: scaleY(-1);
}

.rc-tooltip-ada-placement-bottom .rc-tooltip-ada-arrow {
  left: 50%;
}

/* PHONE */

.rc-tooltip-phone {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px;
  background-color: transparent;
  padding: 1px;
  padding-bottom: -3px;
  opacity: 0.8;
  transition: all 350ms ease-in-out !important;
}
.rc-tooltip-phone-hidden {
  visibility: hidden;
  opacity: 0;
}

.rc-tooltip-phone-inner {
  padding: 8px 20px !important;
  color: #ffffff !important;
  text-align: left !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  min-height: 34px !important;
  border: 1px solid #083e66 !important;
  margin-bottom: 3px !important;
  max-width: 350px !important;
  background-color: #131313;
}

.rc-tooltip-phone-arrow,
.rc-tooltip-phone-arrow-inner {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 3px;
}

.rc-tooltip-phone-placement-bottom .rc-tooltip-phone-arrow {
  top: -5px;
  margin-left: -12px;
  border-width: 12px 12px 0;
  border-top-color: #131313;
  transform: scaleY(-1);
}

.rc-tooltip-phone-placement-bottom .rc-tooltip-phone-arrow {
  left: 50%;
}
