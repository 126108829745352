/* BEM naming convention */

.invBtn__copyBtn {
  width: 25%;
  color: #ffffff;
  font-family: Swiss721 BT;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  text-transform: none;
}

.invBtn__link {
  width: 75%;
  background-color: #ffffff;
  color: #083e66;
  font-family: Swiss721 BT;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  text-transform: none;
  cursor: default;
  user-select: text;
}

/* https://projects.invisionapp.com/d/main#/console/16697026/350738959/inspect */
.invBtn__underText,
.invBtn__underText--hidden {
  margin: 0;
  font-family: Swiss721 BT;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  color: #083e66;
}

.invBtn__underText--hidden {
  visibility: hidden;
}
