.notif__nav {
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.notif__nav > div {
  display: flex;
  flex-flow: column nowrap;
  width: 275px;
}

.notif__nav > div > a > button {
  width: 100%;
}
