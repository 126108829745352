.pretty.p-default input:checked ~ .state label:after {
  background-color: #083e66 !important;
  border-color: #083e66 !important;
}

.state {
  display: flex;
}

.pretty .state label {
  text-indent: 2em !important;
}

.pretty .ada label {
  color: #c15d26 !important;
}

.pretty .ada label:before {
  border-color: #c15d26 !important;
}

.pretty.p-default input:checked ~ .ada label:after {
  background-color: #c15d26 !important;
  border-color: #c15d26 !important;
}

.register {
  display: flex;
}

.pretty .register label {
  text-indent: 2em !important;
}

.pretty .register label:before {
  border-color: #083e66 !important;
}
